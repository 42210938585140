.Toastify__toast--error {
    border-radius: 10px !important;
    background: #EF4444 !important;
}

.Toastify__toast--error::after {
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
}

.Toastify__toast--error::before {
    content: url("assets/icons/errorToast.svg");
    position: relative;
    z-index: 100000;
    margin-right: 8px;
}

.Toastify__toast--success {
    border-radius: 10px !important;
    background: #22C55E !important;
}

.Toastify__toast--success::before {
    content: url("assets/icons/successToast.svg");
    position: relative;
    z-index: 100000;
    margin-right: 8px;
}

.Toastify__toast--success::after {
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
}

.Toastify__toast--warning {
    border-radius: 10px !important;
    background: #0148D2 !important;
}

.Toastify__toast--warning::before {
    content: url("assets/icons/warningToast.svg");
    position: relative;
    z-index: 100000;
    margin-right: 8px;
}

.Toastify__toast--warning::after {
    position: absolute;
    color: #E78326;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
}

.Toastify__toast-body {
    align-items: flex-start !important;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    min-height: 30px;
    font-weight: 400;
    padding: 0px !important;
    margin-right: 10px !important;
    border-right: 1px solid #ffffff84;
}

.Toastify__toast-icon {
    display: none !important;
}

.Toastify__toast {
    padding: 16px !important;
}

.Toastify__toast>button>svg {
    color: #ffffff;
}

.Toastify__close-button--light {
    opacity: 1 !important;
}

.Toastify__progress-bar {
    background-color: white !important;
}