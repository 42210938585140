.mv-table {
    table-layout: fixed;
    width: 100%;
}

.mv-table-container {
    height: 74vh;
    overflow-y: auto;
    padding-bottom: 80px;
}

.mv-table>thead {
    position: sticky;
    top: 0;
    background-color: white;
}

.mv-table>thead>tr>th>p,
.mv-table>tbody>tr>td>p,
.mv-table>tbody>tr>td>input {
    padding: 18px 24px;
    border-bottom: 1px solid #E3E5EB;
}

.mv-table>thead>tr>th>p {
    border-top: 1px solid #E3E5EB;
}

.mv-table>thead>tr>th:first-child,
.mv-table>tbody>tr>td:first-child {
    border-right: 1px solid #E3E5EB;
}

.mv-text {
    text-align: left;

}

.mv-input {
    width: 100%;
    outline: 0;
}