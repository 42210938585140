.cc-nav-link {
  @apply flex items-center font-medium text-sm gap-2 py-2 px-[19px] cursor-pointer !important;
}

.cc-nav-link--inactive {
  @apply text-paragraph hover:text-headers hover:bg-grey-100 border-r-[5px] border-r-transparent !important;
}

.cc-nav-link--active {
  @apply text-primary-actions border-r-[5px] border-r-primary-actions bg-primary-50 !important;
}
