.react-datepicker-wrapper {
	border-color: '#DFE2E2' !important;
	width: 100%;
}

.react-datepicker__input-container {
	position: relative;
	display: flex;
	width: 100%;
}

.react-datepicker__close-icon {
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: 0;
	padding: 0 8px;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}