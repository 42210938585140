.cc-radio {
  @apply checked:bg-primary-actions hover:border-primary-actions rounded-full border border-neutral-10 bg-white checked:border-primary-actions focus:border-primary-actions text-neutral-base focus:ring focus:ring-primary-actions disabled:bg-grey-150 disabled:border-neutral-10 disabled:text-neutral-10 transition-all duration-300 ease-out !important;
}

.cc-radio--16 {
  @apply w-4 h-4 !important;
}

.cc-radio--18 {
  @apply w-[18px] h-[18px] !important;
}

.cc-radio--24 {
  @apply w-6 h-6 !important;
}

.cc-radio--32 {
  @apply w-8 h-8 !important;
}
.cc-radio--36 {
  @apply w-9 h-9 !important;
}
.cc-radio--44 {
  @apply w-[32px] h-[32px] !important;
}
