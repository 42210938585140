@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Inter:wght@100..900&family=Roboto:wght@500&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: rgba(128, 128, 128, 0.084);
}

/* Slide up */
@keyframes slideUp {
  from {
    transform: translateY(2%);
  }

  to {
    transform: translateY(0);
  }
}

.slide-up {
  animation: slideUp;
  transform: translateZ(0);
}

/* Slide left */
@keyframes slideLeft {
  from {
    transform: translateX(10%);
  }

  to {
    transform: translateX(0);
  }
}

.slide-left {
  animation: slideUp;
  transform: translateZ(0);
}

.selected-tab {
  font-weight: 700;
  color: #2B892B;
  border-bottom: 2px solid #2B892B;
}

.unselected-tab {
  font-weight: 700;
  color: #A1A1A1;
  border-bottom: 1px solid transparent;
}