.ai-table {
    table-layout: fixed;
    width: 100%;
}

.ai-table>thead {
    background-color: white;
}

.ai-table>thead>tr>th>p,
.ai-table>tbody>tr>td>p ,
.ai-table>thead>tr>th,
.ai-table>tbody>tr>td {
    padding: 12px;
    border-bottom: 1px solid #E3E5EB;
}

.ai-table>thead>tr>th:first-child,
.ai-table>tbody>tr>td:first-child {
    border-right: 1px solid #E3E5EB;
}

.ai-text {
    text-align: left;
    text-transform: capitalize;
}