.cc-status--active,
.cc-status--completed,
.cc-status--awaiting_start_date,
.cc-status--resolved,
.cc-status--paid {
    @apply text-success-main bg-success-lightest;
}

.cc-status--pending_approval,
.cc-status--pending {
    @apply text-warning-dark bg-warning-lightest;
}

.cc-status--deactivated,
.cc-status--rejected,
.cc-status--failed,
.cc-status--defaulter,
.cc-status--unpaid {
    @apply text-error-main bg-error-lightest;
}

.cc-status--inactive,
.cc-status--in-progress,
.cc-status--no_data_source, 
.cc-status--livelihood{
    @apply bg-grey-100 text-headers;
}

.cc-status--no-loan-collected{
    @apply bg-blue-100 text-blue-600;
}