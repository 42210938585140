.cc-custom-table-data-row {
    @apply font-medium border-b-[0.5px] border-grey-200  text-body
}

.cc-custom-table-data-row>td {
    @apply text-sm font-normal px-4 py-2.5
}

.cc-custom-table-data-row:hover {
    @apply transition-all ease-in-out duration-300
}