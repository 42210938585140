/* Modal Styles */
.overlay {
    width: 100%;
    height: 100vh;
    background: #17191FCC;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
}

.modal {
    position: fixed;
    z-index: 30;
}

.modal-center {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    transition: opacity 1s;
    animation: modalUp 0.5s;
}

.modal-right {
    top: 16px;
    right: 16px;
    opacity: 1;
    transition: 1s;
    display: flex;
    animation: slideLeft 0.5s;
    transform: translateZ(0);
    height: 96vh;
}

.modal-right-body {
    height: 85vh;
    padding: 24px 32px 90px;
    overflow-y: auto;
}

.modal-right-button-container {
    display: flex;
    padding: 12px 32px 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border-top: 1.2px solid #E6E6E6;
}

@keyframes modalUp {
    from {
      top: 60%;
    }
    to {
      top: 50%;
    }
  }