.animate-dialog {
    transition: opacity 1s;
    animation: dialogUp 0.5s;
    transform-origin: bottom;
}

@keyframes dialogUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
}

@keyframes dialogDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.animate-dialog.exit {
  animation: dialogDown 0.5s;
}