.loading-indicator {
    animation: rotate 1s linear infinite;
    margin-left: 3px;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }