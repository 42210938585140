.cc-pagination-container {
  @apply flex items-center justify-between gap-2 w-full;
}

.cc-pagination-number-button-inactive {
  @apply w-8 h-8 grid content-center justify-items-center rounded text-body font-normal text-sm transition-all duration-500 ease-out disabled:cursor-not-allowed !important;
}

.cc-pagination-number-button-active {
  @apply w-8 h-8 grid content-center justify-items-center rounded bg-neutral-5 font-bold text-sm transition-all duration-500 ease-out disabled:cursor-not-allowed !important;
}
