.cc-avatar {
  @apply grid justify-items-center content-center bg-neutral-5 rounded-full overflow-hidden text-neutral-base;
}

.cc-avatar--image {
  @apply object-contain bg-transparent !important;
}

.cc-avatar--stroke {
  @apply border-dashed border-2 border-neutral-30;
}

.cc-avatar--16 {
  @apply h-4 w-4 font-medium text-[0.375rem];
}

.cc-avatar--24 {
  @apply h-6 w-6 font-semibold text-[0.5rem];
}

.cc-avatar--32 {
  @apply h-8 w-8 font-semibold text-xs;
}

.cc-avatar--40 {
  @apply h-10 w-10 font-semibold text-sm;
}

.cc-avatar--48 {
  @apply h-12 w-12 font-semibold text-base;
}

.cc-avatar--89 {
  @apply h-[89px] w-[89px] font-semibold text-base;
}

.cc-avatar--97 {
  @apply h-[97px] w-[97px] font-semibold text-base;
}
