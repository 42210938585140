.cc-sidebar-item--helper-display {
  border-radius: var(--Medium, 1rem);
  border: 1.2px solid rgba(23, 25, 31, 0.6);

  background: linear-gradient(
      277deg,
      rgba(23, 25, 31, 0.18) 0%,
      rgba(23, 25, 31, 0.06) 100%
    ),
    rgba(23, 25, 31, 0.55);

  backdrop-filter: blur(16px);
}
